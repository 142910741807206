/* Main container styling */
.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #00aaff, #004d61); /* Ocean-inspired gradient */
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  animation: fadeIn 1s ease-in-out; /* Animation for fading in */
}

/* Title styling */
.signin-container h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #fff;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3); /* Text shadow for better readability */
  letter-spacing: 1.5px;
  animation: slideUp 1s ease-in-out; /* Slide-up animation for title */
}

/* Form container styling */
.signin-form, .otp-form {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 2.5rem;
  width: 100%;
  max-width: 450px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease-in-out; /* Fade-in animation for form */
}

/* Input group styling */
.input-group {
  margin-bottom: 1.5rem;
}

.input-group label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
}

.input-group input {
  padding: 0.9rem;
  border-radius: 8px;
  border: 1px solid #A6AEBF;
  font-size: 1rem;
  width: 100%;
  outline: none;
  transition: border 0.3s, box-shadow 0.3s;
  background-color: #f9f9f9; /* Slight off-white background for inputs */
}

.input-group input:focus {
  border-color: #00aaff;
  box-shadow: 0 0 8px rgba(0, 170, 255, 0.3);
}

/* Button styling */
button[type="submit"], .forgot-button, .register-here-button {
  background-color: #00aaff; /* Ocean blue */
  color: #fff;
  padding: 0.9rem;
  width: 100%;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 1.5rem;
}

button[type="submit"]:hover, .forgot-button:hover, .register-here-button:hover {
  background-color: #008cba; /* Darker shade of blue on hover */
  transform: scale(1.05); /* Slight scaling effect on hover */
}

button[type="submit"]:disabled, .forgot-button:disabled, .register-here-button:disabled {
  background-color: #C5D3E8;
  cursor: not-allowed;
}

/* Success message styling */
.success-message {
  color: #28a745;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  text-align: center;
}

/* Error message styling */
.error-message {
  color: #ff4d4f;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  text-align: center;
}

/* Link button styling */
.register-here-button {
  background-color: transparent;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.register-here-button:hover {
  color: #00aaff; /* Highlight color on hover */
}

/* Animation Keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .signin-form, .otp-form {
    width: 90%;
    padding: 2rem;
  }

  .signin-container h2 {
    font-size: 2rem;
  }

  button[type="submit"], .forgot-button, .register-here-button {
    font-size: 1rem;
  }

  .input-group input {
    padding: 0.8rem;
  }
}
