/* ResetPassword.css */

body {
  background-color: #FFF8DE; /* Lightest background color */
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.reset-password-container {
  background-color: #C5D3E8; /* Light blue-grey background for the form */
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

h2 {
  color: #A6AEBF; /* Darker blue-grey for the heading */
  text-align: center;
  margin-bottom: 20px;
}

form div {
  margin-bottom: 15px;
}

label {
  display: block;
  color: #4A5568; /* Darker neutral for labels */
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #A6AEBF; /* Border color */
  border-radius: 5px;
  background-color: #FFF8DE; /* Lightest background */
  font-size: 16px;
}

input[type="password"]:focus {
  outline: none;
  border-color: #D0E8C5; /* Soft green on focus */
}

button {
  background-color: #D0E8C5; /* Soft green for button */
  color: #4A5568; /* Darker neutral for button text */
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #A6AEBF; /* Darker blue-grey on hover */
}

button:active {
  background-color: #C5D3E8; /* Slightly lighter blue-grey on click */
}

p {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

p.error-message {
  color: #FF4D4F; /* Red for error messages */
}

p.success-message {
  color: #28A745; /* Green for success messages */
}
