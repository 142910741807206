/* Main container styling */
.forgot-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1e3a5f, #12294c); /* Dark blue gradient */
  color: #fff;
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  animation: fadeIn 1s ease-in-out; /* Animation for fading in */
}

/* Title styling */
.forgot-password-container h2 {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4); /* Subtle shadow for contrast */
  letter-spacing: 1.2px;
  animation: slideUp 1s ease-in-out; /* Slide-up animation for the title */
}

/* Form container styling */
.forgot-password-form {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15); /* Smooth shadow for elevation */
  animation: fadeInUp 1s ease-in-out; /* Fade-in animation for the form */
}

/* Input group styling */
.input-group {
  margin-bottom: 1.5rem;
}

.input-group label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333; /* Neutral dark text color */
}

.input-group input {
  padding: 0.8rem;
  border-radius: 6px;
  border: 1px solid #aeb9cc;
  font-size: 1rem;
  width: 100%;
  outline: none;
  transition: border 0.3s, box-shadow 0.3s;
  background-color: #f6f7f9; /* Light neutral background for inputs */
}

.input-group input:focus {
  border-color: #27578e; /* Blue accent for focus */
  box-shadow: 0 0 8px rgba(39, 87, 142, 0.3);
}

/* Button styling */
.submit-button {
  background-color: #27578e; /* Blue button */
  color: #fff;
  padding: 0.9rem;
  width: 100%;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 1.5rem;
}

.submit-button:hover {
  background-color: #15477c; /* Darker blue on hover */
  transform: scale(1.03); /* Slight scale effect for interaction */
}

.submit-button:disabled {
  background-color: #aac8e0; /* Muted blue for disabled state */
  cursor: not-allowed;
}

/* Success message styling */
.success-message {
  color: #28a745;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  text-align: center;
}

/* Error message styling */
.error-message {
  color: #d9534f;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  text-align: center;
}

/* Animation Keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .forgot-password-form {
    width: 90%;
    padding: 1.8rem;
  }

  .forgot-password-container h2 {
    font-size: 2rem;
  }

  .submit-button {
    font-size: 1rem;
  }

  .input-group input {
    padding: 0.8rem;
  }
}
