/* Center the table container and give it a polished look */
.table-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Style for the heading */
  .table-heading {
    text-align: center;
    margin: 20px 0;
    font-family: "Roboto", sans-serif;
    color: #333;
  }
  
  /* Style for the "Back to Dashboard" button */
  .back-button {
    margin-bottom: 20px;
    color: #ffffff !important;
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
    font-weight: bold;
    text-transform: capitalize;
  }
  
  .back-button:hover {
    background-color: #115293 !important;
    border-color: #115293 !important;
  }
  
  /* Table styling */
  .MuiTable-root {
    border-collapse: collapse;
    width: 100%;
  }
  
  /* Header row styling */
  .MuiTableHead-root {
    background-color: #1976d2;
    color: #ffffff;
  }
  
  .MuiTableCell-head {
    color: #ffffff;
    font-weight: bold;
    text-align: center;
  }
  
  /* Body row styling */
  .MuiTableCell-body {
    text-align: center;
    padding: 12px;
  }
  
  .MuiTableRow-root:nth-of-type(odd) {
    background-color: #f2f2f2;
  }
  
  .MuiTableRow-root:nth-of-type(even) {
    background-color: #ffffff;
  }
  
  /* Style for the "Admin Login" activity */
  .admin-login {
    font-weight: bold;
    color: #d32f2f;
  }
  
  /* Loading spinner styling */
  .loading-spinner {
    display: block;
    margin: 20px auto;
    color: #1976d2 !important;
  }
  
  /* Style for empty logs message */
  .no-logs-message {
    text-align: center;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #666;
    margin: 20px 0;
  }
  