/* UserProfile.css */

/* Main container styling */
.user-profile-container {
    background-color: #C5D3E8;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Title styling */
.user-profile-container h1 {
    color: #333333;
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

/* Form styling */
form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Text field styling */
.MuiTextField-root {
    background-color: #FFF8DE;
    border-radius: 5px;
}

.MuiOutlinedInput-root {
    color: #333333;
}

.MuiInputLabel-outlined {
    color: #A6AEBF;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #A6AEBF;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #D0E8C5;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #A6AEBF;
}

/* Error message styling */
.error-message {
    color: #FF4D4F;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
}

/* Button styling */
.MuiButton-root {
    background-color: #A6AEBF;
    color: #FFF8DE;
    font-size: 1rem;
    padding: 0.7rem 2rem;
    border-radius: 5px;
    margin-top: 1rem;
    transition: background-color 0.3s ease;
}

.MuiButton-root:hover {
    background-color: #D0E8C5;
    color: #333333;
}

/* Loading state styling */
.MuiButton-root:disabled {
    background-color: #C5D3E8;
    color: #999999;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .user-profile-container h1 {
        font-size: 2rem;
    }

    .MuiButton-root {
        padding: 0.6rem 1.5rem;
        font-size: 0.9rem;
    }
}
