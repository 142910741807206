/* General Styling */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f6f9;
  margin: 0;
  padding: 0;
}

/* Admin Dashboard Container */
.admin-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Styling */
.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0078d4;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.admin-header h1 {
  margin: 0;
  font-size: 2rem;
}

.logout-button {
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.logout-button:hover {
  background-color: #d43b3b;
  transform: scale(1.05);
}

/* Main Content Styling */
.admin-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
}

.admin-section {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  flex: 1 1 calc(33.333% - 40px);
  min-width: 300px;
  max-width: 400px;
  transition: all 0.3s ease;
}

.admin-section:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.admin-section h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #0078d4;
}

.admin-section p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

/* Buttons */
.admin-action-button {
  background-color: #0078d4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.admin-action-button:hover {
  background-color: #005bb5;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .admin-content {
    flex-direction: column;
  }
}
